import React from "react"
import { createGlobalStyle } from "styled-components"

import ImageModal from "../../containers/ImageModal"

const CardsStyles = createGlobalStyle`
  img.card-image {
    object-fit: cover;
    object-position: top;
    height: 25rem;
    width: 100%;
  }
`

function Cards({ headerData, imagesData }) {
  const { naglowek: headerContent } = headerData[0]
  const imagesModals = imagesData.map(element => {
    const {
      id,
      tytul: title,
      zdjecie: { fluid: imageFluid },
    } = element

    return <ImageModal imageFluid={imageFluid} title={title} key={id} />
  })

  return (
    <div className="container">
      <CardsStyles />
      <div className="row">
        <h3 className="text-center mt-6 mb-6 pl-2 pr-2">{headerContent}</h3>
      </div>
      <div className="row mb-6">
        <div className="col-12 col-md-6 col-lg-4">{imagesModals[0]}</div>
        <div className="col-md-6 col-lg-4 d-none d-md-block">
          {imagesModals[1]}
        </div>
        <div className="col-lg-4 d-none d-lg-block">{imagesModals[2]}</div>
      </div>
    </div>
  )
}

export default Cards
