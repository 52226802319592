import React from "react"

import Hero from "./Hero"
import Cards from "./Cards"
import Certificates from "./Certificates"

function About({ data }) {
  const {
    allContentfulAboutTexts: { nodes: heroData },
    allContentfulAboutHeader: { nodes: headerData },
    allContentfulAboutImage: { nodes: imagesData },
    allContentfulAboutDyplom: { nodes: certificatesData },
  } = data

  return (
    <>
      <Hero heroData={heroData} />
      <Cards headerData={headerData} imagesData={imagesData} />
      <Certificates certificatesData={certificatesData} />
    </>
  )
}

export default About
