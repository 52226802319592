import React from "react"
// import { Link } from "gatsby"
import styled from "styled-components"

import heroImage from "../../assets/images/aboutMeImage.jpg"

const HeroImage = styled.div`
  height: 641px;
  background-image: url(${heroImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const HeroText = styled.div`
  display: flex;
  align-items: center;
  padding: 4rem 5rem;
  color: white;
  /* background-color: #e5007d; */
  background-color: rgb(27, 27, 58);

  @media (min-width: 992px) {
    height: 641px;
  }
`

const P = styled.p`
  font-size: 16px;
  opacity: 0.8;
`

function Hero({ heroData }) {
  const {
    opis: { opis: description },
    tytul: title,
  } = heroData[0]

  return (
    <div className="container-fluid">
      <div className="row">
        <HeroText className="col-12 col-lg-6">
          <div>
            <h1>{title}</h1>
            <P className="mt-4">{description}</P>
            <button
              className="button-primary mt-4"
              onClick={() => window.location.replace("/prices")}
            >
              Sprawdź cennik
            </button>
          </div>
        </HeroText>
        <HeroImage className="col-12 col-lg-6"></HeroImage>
      </div>
    </div>
  )
}

export default Hero
