import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import About from "../components/About"

export const query = graphql`
  {
    allContentfulAboutTexts {
      nodes {
        opis {
          opis
        }
        tytul
      }
    }
    allContentfulAboutHeader {
      nodes {
        naglowek
      }
    }
    allContentfulAboutImage {
      nodes {
        id
        tytul
        zdjecie {
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    allContentfulAboutDyplom {
      nodes {
        id
        tytul
        dyplom {
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default function AboutPage({ data }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>fizjowsulkowski | O mnie</title>
        <meta name="theme-color" content={"white"} />
      </Helmet>
      <About data={data} />
    </>
  )
}
