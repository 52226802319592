import React from "react"
import { Container, Row, Col, Pagination } from "react-bootstrap"
import { createGlobalStyle } from "styled-components"
import ImageModal from "../../containers/ImageModal"

const PaginationStyles = createGlobalStyle`
    a.page-link {
        &:hover {
            color: #e5007d;
        }
        color: #e5007d;
    }

    li.active span {
        background-color: #e5007d!important;
        border: 1px solid #e5007d!important;
    }
`

function Certificates({ certificatesData }) {
  const [activeItem, setActiveItem] = React.useState(1)
  const sliceStart = (activeItem - 1) * 6
  const sliceEnd = sliceStart + 6
  const images = []

  certificatesData.forEach(element => {
    const {
      id,
      tytul: title,
      dyplom: { fluid: imageFluid },
    } = element

    images.push(
      <Col xs={6} md={4} lg={2} className="mt-4" key={id}>
        <ImageModal imageFluid={imageFluid} title={title} />
      </Col>
    )
  })

  let items = []
  for (let number = 1; number <= Math.ceil(images.length / 6); number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === activeItem}
        onClick={() => setActiveItem(number)}
      >
        {number}
      </Pagination.Item>
    )
  }

  return (
    <Container>
      <Row>
        <h1 className="ml-auto mr-auto page-header">Moje dyplomy</h1>
      </Row>
      <Container className="mt-6 mb-6">
        <PaginationStyles />
        <Row className="mt-5">{images.slice(sliceStart, sliceEnd)}</Row>
        <Row className="mt-5">
          {items.length > 1 && (
            <Pagination className="ml-auto mr-auto">{items}</Pagination>
          )}
        </Row>
      </Container>
    </Container>
  )
}

export default Certificates
